const crypto = require("crypto");
const client = require("@mailchimp/mailchimp_marketing");

exports.updateNewsletterSubscription = async (data) => {
  try {
    client.setConfig({
      apiKey: process.env.MAILCHIMP_KEY,
      server: process.env.MAILCHIMP_SERVER_PREFIX,
    });

    const emailHash = crypto
      .createHash("md5")
      .update(data.email.toLowerCase())
      .digest("hex");

    if (data.subscribe) {
      const response = await client.lists.setListMember(
        process.env.MAILCHIMP_LIST_ID,
        emailHash,
        { email_address: data.email.toLowerCase(), status_if_new: "subscribed" }
      );

      return {
        status: `The email ${data.email.toLowerCase()} have been successfully added to our ${
          response.status
        } list`,
        statusCode: 200,
      };
    } else {
      const response = await client.lists.setListMember(
        process.env.MAILCHIMP_LIST_ID,
        emailHash,
        {
          email_address: data.email.toLowerCase(),
          status_if_new: "unsubscribed",
        }
      );
      return {
        status: `The email ${data.email.toLowerCase()} have been successfully removed from our ${
          response.status
        } list`,
        statusCode: 200,
      };
    }
  } catch (err) {
    return { status: err.message, statusCode: err.status };
  }
};
